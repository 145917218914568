const WebsitePageFragment = `
id
slug
parent{
    id
}
enabled
locked
enableLocked
metadata{
    indexed
    title
    description
    ogTitle
    ogImage
    ogDescription
    ogUrl
}
sections{
    id
    type
    data
    params
    theme
}
`

export default WebsitePageFragment
