import { isBrowser } from '~utils/misc'

import { compress, decompress } from 'lz-string'

export const setLocalStorageItem = (key, value) => {
	if (!isBrowser()) return null

	localStorage.setItem(key, compress(value))
}

export const getLocalStorageItem = (key, parseJSON = false) => {
	if (!isBrowser()) return null

	if (!localStorage.getItem(key)) return null

	if (parseJSON) {
		return JSON.parse(decompress(localStorage.getItem(key)))
	}

	return decompress(localStorage.getItem(key))
}

export const setSessionStorageItem = (key, value) => {
	if (!isBrowser()) return null

	sessionStorage.setItem(key, compress(value))
}

export const getSessionStorageItem = (key) => {
	if (!isBrowser()) return null

	return decompress(sessionStorage.getItem(key))
}
