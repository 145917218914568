import React, { useEffect, useRef, useState } from 'react'

import {
	TNotificationContextData,
	TNotificationItem
} from './Notification.types'

import './Notification.scss'

const uniqid = require('uniqid')

const notificationContext = React.createContext<TNotificationContextData>(null)

export const useNotificationContext = () =>
	React.useContext<TNotificationContextData>(notificationContext)

const NOTIFICATION_DURATION = 1500

export const NotificationProvider = ({ children }) => {
	const [data, setData] = useState<(TNotificationItem & { id: string })[]>([])

	const timeoutIdsRef = useRef([])

	const removeNotification = (notificationId) => {
		setData((data) =>
			data.filter((notification) => notification.id !== notificationId)
		)
	}

	useEffect(() => {
		return () => {
			timeoutIdsRef.current.forEach((id) => {
				clearTimeout(id)
			})
		}
	}, [])

	const push = (notification: TNotificationItem) => {
		const id = uniqid()

		setData([
			...data,
			{
				...notification,
				id
			}
		])

		const timeoutID = setTimeout(() => {
			removeNotification(id)
			clearTimeout(timeoutID)
			timeoutIdsRef.current = timeoutIdsRef.current.filter(
				(id) => id !== timeoutID
			)
		}, NOTIFICATION_DURATION)

		timeoutIdsRef.current.push(timeoutID)
	}

	return (
		<notificationContext.Provider value={{ push }}>
			<div className="Notification">
				{data.map(({ id, ...notification }) => (
					<NotificationItem
						key={id}
						{...notification}
						// handleClose={() => {
						// 	removeNotification(id)
						// }}
					/>
				))}
			</div>

			{children}
		</notificationContext.Provider>
	)
}

const NotificationItem = ({ text, theme = 'normal' }) => (
	<div
		className={`NotificationItem theme-${theme}`}
		style={{
			animationDuration: `${NOTIFICATION_DURATION}ms`
		}}
	>
		{/* <button onClick={handleClose}>
			<i className="mdi mdi-close" />
		</button> */}
		<div className="notification-text">{text}</div>
	</div>
)
