/*
 * Common function to call API.
 * As we migrate API to a new version, some queries are made
 * to new one and other to the last one. We'll migrate to
 * the new one step by step.
 */

import ApolloClient, { InMemoryCache } from 'apollo-boost'
import { forceLogout } from '~contexts/authContext/authContext'
import { isBrowser } from '~utils/misc'

const jwt = require('jsonwebtoken')

type TApiVersion = 1 | 2

// Remove double slash when api url environemnt variable has a slash at end.
export const cleanURL = (url: string) => url.replace(/([^:]\/)\/+/g, '$1')

const generateHeaders = (token = null) => {
	const headers = {
		'x-website-token': process.env.GATSBY_X_WEBSITE_TOKEN,
		'x-universe-token': process.env.GATSBY_CLIENT_TOKEN,
		'public-client-verification': jwt.sign(
			{
				sub: new Date().getTime()
			},
			process.env.GATSBY_JWT_SECRET
		)
	}

	if (token) {
		headers['x-access-token'] = token
	}

	return headers
}

const client = (apiVersion: TApiVersion = 2, token = null): any =>
	isBrowser()
		? new ApolloClient({
				cache: new InMemoryCache({
					addTypename: false
				}),
				uri: cleanURL(
					`${
						apiVersion === 1
							? process.env.GATSBY_API_URI
							: process.env.GATSBY_APIV2_URI
					}/graphql`
				),
				headers: generateHeaders(token)
		  })
		: {}

type doGraphqlQueryPropsType = {
	query: import('graphql').DocumentNode
	variables?: any
	noCache?: boolean
	apiVersion?: TApiVersion
	token?: string
}

export const doGraphqlQuery = async ({
	query,
	variables = {},
	noCache = false,
	apiVersion = 1,
	token = null
}: doGraphqlQueryPropsType) => {
	// @ts-ignore
	const { data } = await client(apiVersion, token).query({
		query,
		variables,
		...(noCache ? { fetchPolicy: 'no-cache' } : {})
	})

	return data
}

type doGraphqlMutationPropsType = {
	mutation: any
	variables?: any
	noCache?: boolean
	apiVersion?: TApiVersion
	token?: string
}

export const doGraphqlMutation = async ({
	mutation,
	variables = {},
	noCache = false,
	apiVersion = 1,
	token = null
}: doGraphqlMutationPropsType) => {
	try {
		const { data } = await client(apiVersion, token).mutate({
			mutation,
			variables,
			...(noCache ? { fetchPolicy: 'no-cache' } : {})
		})

		return data
	} catch (err) {
		const hasAccessTokenError = err.graphQLErrors.find(
			({ message }) => message.statusCode === 403
		)

		if (hasAccessTokenError) {
			forceLogout()
		}
	}
}
