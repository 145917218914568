import React, { useEffect, useState } from 'react'
import { TWebsite, TWebsitePage } from '~types/Website.types'
import useLocalStorage, { getLocalStorageValue } from './useLocalStorage'

import { useWebsiteQueries } from '~queries/website.queries'
import { useAuthContext } from '~contexts/authContext/authContext'
import { useNotificationContext } from '~components/Common/Notification/Notification'
import useIsMounted from './useIsMounted'
import { TUseWebsite } from './useWebsite.types'

const websiteContext = React.createContext(null)

export const WebsiteDataProvider = ({ children }) => {
	// @ts-ignore
	const [website, setWebsite] = useLocalStorage<TWebsite>('website', null)
	const [isLoading, setIsLoading] = useState(website === null)
	const websiteQueries = useWebsiteQueries()
	const isMounted = useIsMounted()
	const push = useNotificationContext()?.push

	const refreshData = () => {
		websiteQueries
			.getWebsite()
			.then((website: any) => {
				if (!isMounted()) return

				if (!website.activitiesId && Array.isArray(website.activities)) {
					website.activitiesId = []
					website.activities.map(({ id }) => website.activitiesId.push(id))
				}
				// if (!website.plan?.type) {
				// 	website.plan = {
				// 		type: 'standard',
				// 		expirationDate: new Date()
				// 	}
				// }
				//console.log(`website`, website)
				setWebsite(website)
				setIsLoading(false)
			})
			.catch(() => {
				if (!isMounted()) return
				push?.({
					text: 'Une erreur est survenue!',
					theme: 'danger'
				})
			})
	}

	useEffect(refreshData, [])

	return (
		<websiteContext.Provider
			value={{ website, setWebsite, refreshData, isLoading }}
		>
			{children}
		</websiteContext.Provider>
	)
}

const useWebsite = (): TUseWebsite => {
	const { website, setWebsite, refreshData, isLoading } = React.useContext(
		websiteContext
	)

	const websiteQueries = useWebsiteQueries()
	const [, setModified] = useState(false)
	const authContext = useAuthContext()
	const push = useNotificationContext()?.push

	const update = (update: Partial<Omit<TWebsite, ''>>) => {
		return new Promise<void>((res, rej) => {
			setModified(true)

			setWebsite((website: TWebsite) => {
				const data: TWebsite = {
					...website,
					...update
				}

				save(data).then(res).catch(rej)
				return data
			})
		})
	}

	const addPage = (page: TWebsitePage) =>
		new Promise<TWebsitePage>((res) => {
			websiteQueries.createPage(page).then((createdPage: TWebsitePage) => {
				setWebsite((website_: TWebsite) => {
					const website = getLocalStorageValue('website') || website_

					save({
						...website,
						pages: [...website.pages, createdPage]
					} as any)

					res(createdPage)

					return {
						...website,
						pages: [...website.pages, createdPage]
					}
				})
			})
		})

	const removePage = (page: TWebsitePage) => {
		const { id } = page
		websiteQueries
			.removePage(id)
			.then(() => {
				setWebsite((website_: TWebsite) => {
					const website = getLocalStorageValue('website') || website_

					const data: any = {
						...website,
						pages: website.pages.filter((page) => page.id !== id)
					}

					save(data)

					return data
				})
			})
			.catch((_err) => {
				push?.({
					text: 'Impossible be supprimer la page',
					theme: 'danger'
				})
			})
	}

	const updatePage = ({ id, ...data }: TWebsitePage) => {
		return websiteQueries.updatePage(id, data).then((page) => {
			setWebsite((website_: TWebsite) => {
				const website = getLocalStorageValue('website') || website_

				return {
					...website,
					pages: website.pages.map((_) => (_.id === id ? page : _))
				}
			})
		})
	}

	// solution temporaire
	const updatePages = (pages: TWebsitePage[]) => {
		for (const page of pages) {
			updatePage(page)
		}
	}

	const rebuild = () => {
		return websiteQueries.rebuild(website?.netlify?.siteId)
	}

	const getPageFromSlug = (slug: string) => {
		return website.pages.find((page) => page.slug === slug)
	}

	const getPage = (id: string) => {
		return website.pages.find((page) => page.id === id)
	}

	const getPaletteColor = (colorName: string) => {
		if (website?.theme?.colors[colorName])
			return website?.theme?.colors[colorName]

		const findColor = website?.theme?.colors.customs.find(
			({ id }) => id === colorName
		)

		if (findColor) return findColor.hex

		return colorName
	}

	const getSectionTheme = (id: string) => {
		const find = website?.theme?.sectionThemes?.find(
			(sectionTheme) => sectionTheme.id === id
		)

		if (!find) return null

		return find
	}

	const save = async ({ _id, _activities, pages, ...website }: any) => {
		await websiteQueries
			.updateWebsite(
				{
					pages: pages.map(({ id }) => id),
					...website
				},
				authContext.token
			)
			.then(() => {
				//console.log('super')
				setModified(false)
			})
			.catch(() => {
				push?.({
					text: "Impossible d'enregistrer les modifications",
					theme: 'warning'
				})
			})
	}

	return {
		...website,
		update,
		getPage,
		getPageFromSlug,
		addPage,
		removePage,
		updatePage,
		updatePages,
		getPaletteColor,
		getSectionTheme,
		loaded: !isLoading,
		refreshData,
		rebuild
	}
}

export default useWebsite

export const generateNewPage = (): TWebsitePage => ({
	slug: 'nouvelle-page',
	type: 'custom',
	enabled: false,
	locked: false,
	enableLocked: false,
	slugLocked: false,
	sections: [],
	metadata: {
		indexed: true,
		description: '',
		ogDescription: '',
		ogImage: '',
		ogTitle: '',
		ogUrl: '',
		title: ''
	}
})
