import React from 'react'

import { AuthContextProvider } from '~contexts/authContext/authContext'
import { BasketContextProvider } from '~contexts/basketContext'
import { GlobalContextProvider } from '~contexts/globalContext'
import { WebsiteDataProvider } from '~hooks/useWebsite'

import './src/style/global.scss'

export const wrapRootElement = ({ element }) => {
	return (
		<WebsiteDataProvider>
			<AuthContextProvider>
				<GlobalContextProvider>
					<BasketContextProvider>{element}</BasketContextProvider>
				</GlobalContextProvider>
			</AuthContextProvider>
		</WebsiteDataProvider>
	)
}
