import gql from 'graphql-tag'
import { doGraphqlQuery } from '~queries/common.queries'

/*
 * Use coq-trotteur API to login.
 */
export const queryLogin = async (email: string, password: string) => {
	const query = gql`
		query login($loginInput: LoginInput!) {
			login(loginInput: $loginInput)
		}
	`

	const variables = {
		loginInput: {
			username: email,
			password,
			websiteToken: process.env.GATSBY_X_WEBSITE_TOKEN
		}
	}

	const { login } = await doGraphqlQuery({
		query,
		variables,
		apiVersion: 2
	})

	if (!login?.token || !login?.user) throw 'error'

	return login
}
