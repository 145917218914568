import React, { useState } from 'react'
import moment from 'moment'

import { TBasketContext, TBasketContextItem } from './basketContext.types'
import { getLocalStorageItem, setLocalStorageItem } from './storage'

const uniqid = require('uniqid')

const basketContext = React.createContext<TBasketContext>({
	items: []
})

export const useBasketContext = () =>
	React.useContext<TBasketContext>(basketContext)

export const BasketContextProvider = ({ children }) => {
	const [basket, _setBasket] = useState<{ items: TBasketContextItem[] }>(
		getLocalStorageItem('basket', true) || {
			items: []
		}
	)

	const setBasket = (basket) => {
		setLocalStorageItem('basket', JSON.stringify(basket))
		_setBasket(basket)
	}

	const addItem = (item) => {
		setBasket({
			...basket,
			items: [...basket.items, createTicket(item)]
		})
	}

	const removeItem = (item) => {
		setBasket({
			...basket,
			items: basket.items.filter(({ id }) => id !== item.id)
		})
	}

	const removeMany = (items) => {
		const ids = items.map(({ id }) => id)

		setBasket({
			...basket,
			item: basket.items.filter(({ id }) => !ids.includes(id))
		})
	}

	const getTotal = () => {
		return basket.items.reduce((total, item) => total + item.item.total, 0)
	}

	const clear = () => {
		setBasket({
			items: []
		})
	}

	return (
		<basketContext.Provider
			value={{
				items: basket.items,
				addItem,
				removeItem,
				removeMany,
				getTotal,
				clear
			}}
		>
			{children}
		</basketContext.Provider>
	)
}

export const createTicket = (item = null) => {
	return {
		date: moment().toISOString(),
		item,
		id: uniqid()
	}
}
