import React from 'react'
import useWebsite from '~hooks/useWebsite'
import { TGlobalContext } from './globalContext.types'

export const globalContext = React.createContext<TGlobalContext>(null)

export const useGlobalContext = () =>
	React.useContext<TGlobalContext>(globalContext)

export const GlobalContextProvider = ({ children }) => {
	const website = useWebsite()

	return (
		<globalContext.Provider value={{ website }}>
			{children}
		</globalContext.Provider>
	)
}
