import { gql } from 'apollo-boost'

import { TWebsite, TWebsitePage } from '~types/Website.types'

import { doGraphqlMutation, doGraphqlQuery } from '~queries/common.queries'
import { useAuthContext } from '~contexts/authContext/authContext'
import WebsitePageFragment from './fragments/websitePage.fragment'
import WebsiteFragment from './fragments/website.fragment'

export const useWebsiteQueries = () => {
	const authContext = useAuthContext()

	const websiteId = process.env.GATSBY_WEBSITE_ID

	const updateWebsite = async (websiteInput, token = null) => {
		// console.log(`webnsiteInput`, websiteInput)
		const mutation = gql(`
			mutation UpdateWebsite( $websiteInput: WebsiteInput!) {
				updateWebsite(websiteInput: $websiteInput){
					${WebsiteFragment}
				}
			}
		`)
		delete websiteInput.activities
		delete websiteInput.id
		const variables = {
			websiteInput
		}

		const { updateWebsite: website } = await doGraphqlMutation({
			mutation,
			variables,
			apiVersion: 2,
			token
		})

		return website
	}

	const getWebsite = async (): Promise<TWebsite> => {
		const query = gql(`
			query FetchWebsite($websiteId: ID!) {
				fetchWebsite(websiteId: $websiteId) {
                    ${WebsiteFragment}
				}
			}
		`)

		const variables = {
			websiteId
		}

		const { fetchWebsite: website } = await doGraphqlQuery({
			query,
			variables,
			apiVersion: 2
		})

		return website
	}

	const createPage = async (websitePageInput: TWebsitePage) => {
		const mutation = gql(`
			mutation CreateWebsitePage($websitePageInput: WebsitePageInput!) {
				createWebsitePage( websitePageInput: $websitePageInput){
					${WebsitePageFragment}
				}
			}
		`)

		const variables = {
			websitePageInput
		}

		const { createWebsitePage: page } = await doGraphqlMutation({
			mutation,
			variables,
			apiVersion: 2,
			token: authContext.token
		})

		return page
	}

	const removePage = async (websitePageId: string): Promise<boolean> => {
		const mutation = gql(`
			mutation RemoveWebsitePage($websitePageId: ID!) {
				removeWebsitePage( websitePageId: $websitePageId)
			}
		`)

		const variables = {
			websitePageId
		}

		const { removeWebsitePage: success } = await doGraphqlMutation({
			mutation,
			variables,
			apiVersion: 2,
			token: authContext.token
		})

		return success
	}

	const getPage = async (_pageId: string) => {
		// ok
	}

	const updatePage = async (
		websitePageId: string,
		websitePageInput: TWebsitePage
	) => {
		// console.log(`websitePageInput`, websitePageInput)
		const mutation = gql(`
			mutation UpdateWebsitePage($websitePageId: ID!, $websitePageInput: WebsitePageInput!) {
				updateWebsitePage( websitePageId: $websitePageId, websitePageInput: $websitePageInput){
					${WebsitePageFragment}
				}
			}
		`)

		const variables = {
			websitePageInput,
			websitePageId
		}

		const { updateWebsitePage: page } = await doGraphqlMutation({
			mutation,
			variables,
			apiVersion: 2,
			token: authContext.token
		})

		return page
	}

	const rebuild = async (siteId: string) => {
		const query = gql(`
			query BuildNetlifySite($siteId: String!) {
				buildNetlifySite( siteId: $siteId)
			}
		`)

		const variables = {
			siteId
		}

		const { buildNetlifySite: success } = await doGraphqlQuery({
			query,
			variables,
			apiVersion: 2,
			token: authContext.token
		})

		return success
	}

	const getStockage = async () => {
		const query = gql(`
			query calcUserStockage {
				calcUserStockage
			}
		`)

		const variables = {}

		const { calcUserStockage } = await doGraphqlQuery({
			query,
			variables,
			apiVersion: 2,
			token: authContext.token
		})

		return calcUserStockage
	}

	return {
		updateWebsite,
		getWebsite,
		createPage,
		removePage,
		getStockage,
		getPage,
		updatePage,
		rebuild
	}
}
