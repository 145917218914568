/* eslint-disable max-len */

import { TPosition } from '~types/common.types'

/* eslint-disable no-useless-escape */
export const isBrowser = () => typeof window !== 'undefined'

export const getUrlParams = () => {
	if (!isBrowser()) return {}

	return document.location.search
		.substr(1)
		.split('&')
		.filter((param) => !!param)
		.map((param) => {
			const split = [...param.split('='), null]

			return {
				[split[0]]: split[1]
			}
		})
		.reduce(
			(acc, cur) => ({
				...acc,
				...cur
			}),
			{}
		)
}

export const getUrlLink = (url) => {
	if (!url.includes('https://') && !url.includes('http://'))
		return 'https://' + url

	return url
}

export const capitalize = (string) => {
	return string.charAt(0).toUpperCase() + string.slice(1)
}

export const formatPrice = (price, forceFloat = false) =>
	!price
		? 0
		: forceFloat
		? price.toFixed(2)
		: price % 1
		? price.toFixed(2)
		: price

export const delay = async (ms) =>
	new Promise((res) => {
		setTimeout(res, ms)
	})

export const formatNumber2digit = (number) => `0${number}`.substr(-2)

export const timeout = (ms: number) => new Promise((res) => setTimeout(res, ms))

export function updateArrayItem<T>(update: { id: string } & T) {
	return function (array: ({ id: string } & T)[]) {
		return array.map((item) =>
			item.id === update.id ? { ...item, ...update } : item
		)
	}
}

export function removeArrayItem<T>(update: { id: string } & T) {
	return function (array: ({ id: string } & T)[]) {
		return array.filter((item) => item.id !== update.id)
	}
}

export const getColorLuminosity = (hex: string) => {
	const clean = hex.replace('#', '').toLowerCase()

	if (clean.length !== 6 || clean.match(/[^0-9a-f]/)) {
		throw `incorrect hexadecimal value "${hex}", only accepts 6 characters hex values`
	}

	const split = clean.match(/.{2}/g).map((hex) => parseInt(hex, 16) / 256)

	return split.reduce((acc, cur) => acc + cur, 0) / 3
}

export const camelCaseToKebabCase = (name) =>
	name.replace(/([A-Z])/g, (value) => `-${value.toLowerCase()}`)
export const generateClassNames = (object) =>
	Object.keys(object)
		.map((key) => `${camelCaseToKebabCase(key)}-${object[key]}`)
		.join(' ')

export const generateLoremIpsum = (
	size: 'small' | 'normal' | 'big' | 'two-pg'
) =>
	size === 'small'
		? `Lorem ipsum dolor.`
		: size === 'normal'
		? `Lorem ipsum dolor sit amet consectetur, adipisicing elit.\
Ex velit rem fugiat distinctio! Eligendi qui amet, dolores\
quas deserunt unde ut veritatis odit error quia modi rem magnam ex ipsum.`
		: size === 'big'
		? `Lorem ipsum dolor sit amet consectetur adipisicing elit.\
Quaerat in tempora ducimus, officia expedita corrupti magnam\
laudantium dicta sequi sed facere ratione sunt cumque? Repellat\
molestiae aliquid qui quo quibusdam?`
		: `Lorem ipsum dolor sit amet consectetur adipisicing elit.\
		Quaerat in tempora ducimus, officia expedita corrupti magnam\
		laudantium dicta sequi sed facere ratione sunt cumque? Repellat\
		molestiae aliquid qui quo quibusdam?
		\n
		Lorem ipsum dolor sit amet consectetur adipisicing elit.\
		Quaerat in tempora ducimus, officia expedita corrupti magnam\
		laudantium dicta sequi sed facere ratione sunt cumque? Repellat\
		molestiae aliquid qui quo quibusdam?`

export const linkRegex = /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/

export const isLink = (link: string): link is string => {
	return linkRegex.test(link)
}

export const getYoutubeIdFromURL = (url: string) => {
	const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/
	const match = url.match(regExp)
	return match && match[7].length == 11 ? match[7] : false
}

export const getCursorSelectionRange = (): TSelectionRange => {
	const selection = getSelection()

	return {
		start: selection.getRangeAt(0).startOffset,
		end: selection.getRangeAt(0).endOffset
	}
}

export const setCursorSelectionRange = (
	element: HTMLElement,
	{ start, end }: TSelectionRange
) => {
	const range = new Range()
	range.setStart(element.firstChild, start)
	range.setEnd(element.firstChild, end)
	document.getSelection().removeAllRanges()
	document.getSelection().addRange(range)
}

type TSelectionRange = {
	start: number
	end: number
}

/**
 * Warning /!\ Do not works when inside scroll element other than window.
 */
export const getMousePositionFromMouseEvent = (
	e: React.MouseEvent
): TPosition => {
	const { scrollY, scrollX } = window
	const { clientX, clientY } = e

	return {
		x: clientX + scrollX,
		y: clientY + scrollY
	}
}

export const waitScreenRefresh = () =>
	new Promise((res) => {
		window.requestAnimationFrame(res)
	})

export const transformTypoToStyles = (typo) => {
	if (!typo) return null
	const typos: any = {}
	for (const key of Object.keys(typo)) {
		typos[key] = {
			...typo[key],
			fontStyle: typo[key]?.italic ? 'italic' : ''
		}
	}

	return typos
}
