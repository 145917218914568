import React from 'react'
import { Suspense } from 'react'

import useLocalStorage from '~hooks/useLocalStorage'
import { queryLogin } from './authContext.queries'
import { TAuthContext, TAuthUser } from './authContext.types'

const LoginPage = React.lazy(
	() => import('~components/Pages/LoginPage/LoginPage')
)
const authContext = React.createContext<TAuthContext>({
	isConnected: false,
	user: null,
	token: null,
	login: () => null,
	logout: () => {
		//
	}
})

export const useAuthContext = () => React.useContext<TAuthContext>(authContext)

export const withAuth = (Component) => {
	const Page = () => {
		const authContext = useAuthContext()
		if (!authContext.isConnected)
			return (
				<Suspense fallback={null}>
					<LoginPage />
				</Suspense>
			)
		return <Component />
	}

	return Page
}

export const AuthContextProvider = ({ children }) => {
	const [user, setUser, clearUser] = useLocalStorage<TAuthUser>('user')
	const [token, setToken, clearToken] = useLocalStorage<string>('token')

	const login = async (email: string, password: string) => {
		// console.log('ICI')
		const result = await queryLogin(email, password)

		// console.log(result)

		setUser(result.user)
		setToken(result.token)

		return true
	}
	const logout = () => {
		clearUser()
		clearToken()
	}

	return (
		<authContext.Provider
			value={{
				user,
				isConnected: user !== null,
				token,
				login,
				logout
			}}
		>
			{children}
		</authContext.Provider>
	)
}

export const forceLogout = () => {
	localStorage.clear()

	if (window.parent) {
		window.parent.location.reload()
	} else {
		document.location.reload()
	}
}
