import React, { useState } from 'react'
import { SetStateAction } from 'react'

type TUseLocalStorage<T> = [
	T,
	(value: React.Dispatch<React.SetStateAction<T>>) => void,
	() => void
]

export const getLocalStorageValue = (key) => {
	try {
		const data = localStorage.getItem(key)
		if (!data) return null

		return JSON.parse(data).value
	} catch {
		return null
	}
}

type TUseLocalStorageSetValue<T> = SetStateAction<T>

function useLocalStorage<T>(
	key: string,
	defaultValue: T = null
): TUseLocalStorage<T> {
	const [value, _setValue] = useState<T>(
		getLocalStorageValue(key) || defaultValue
	)

	const setValue = (value: TUseLocalStorageSetValue<T>) => {
		try {
			if (typeof value === 'function') {
				_setValue((_) => {
					// @ts-ignore
					const out = value(_)

					localStorage.setItem(key, JSON.stringify({ value: out }))

					return out
				})
			} else {
				localStorage.setItem(key, JSON.stringify({ value }))
				_setValue(value)
			}
		} catch {
			//
		}
	}

	const clear = () => {
		try {
			_setValue(null)
			localStorage.removeItem(key)
		} catch {
			//
		}
	}

	// @ts-ignore
	return [value, setValue, clear]
}

export default useLocalStorage
