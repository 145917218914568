import ActivityFragment from './activity.fragment'
import WebsitePageFragment from './websitePage.fragment'

const WebsiteFragment = `
id
name
onboarding
#plan {
#  type
#	expirationDate
#}
activities{
  ${ActivityFragment}
}
boutique{
  categories {
    id
    label
    marketItems
    children {
      id
      label
      marketItems
      children {
        id
        label
        marketItems
      }
    }
  }
}
metadata{
  favicon
}
socials{
  linkedIn
  instagram
  facebook
  twitter
  youtube
}
theme{
    colors{
      dark
      light
      primary
      secondary
      success
      danger
      customs {
        id
        name
        hex
      }
    }
    typo{
      h1{
        fontSize
        fontFamily
        italic
      }
      h2{
        fontSize
        fontFamily
        italic
      }
      h3{
        fontSize
        fontFamily
        italic
      }
      p{
        fontSize
        fontFamily
        italic
      }
    }
    footer{
      footerTheme
      showSocialLinks
      display
      showNewsLetter
      copyright
      nav {
        label
        items {
          id
          type
          label
          url
          pageId
          targetBlank
        }
      }
    }
    sectionThemes{
      id
      name
      title
      background
      text
      color
      button{
        color
        background
        
      }
      
    }
    topbar{
      backgroundColor
      textColor
      backgroundColor
      showSocialLinks
      display
      fixed
      menu {
        id
        type
        label
        url
        pageId
        targetBlank
        children {
          id
          type
          label
          url
          pageId
          targetBlank
          children {
            id
            type
            label
            url
            pageId
            targetBlank
            children {
              id
              type
              label
              url
              pageId
              targetBlank
            }
          }
        }
      }
    }
    logo{
      type
      text
      url
    }
}
pages{
    ${WebsitePageFragment}
}
netlify{
  url
  siteId
}
`

export default WebsiteFragment
